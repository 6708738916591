import { forwardRef, PropsWithChildren, Ref, useMemo } from 'react';
export enum VARIANT {
  BIG,
  MEDIUM,
  SMALL,
}
interface Props {
  variant?: VARIANT;
  className?: string;
  isHighlighted?: boolean;
  testId?: string;
}
const Paragraph = forwardRef(({
  children,
  variant = VARIANT.SMALL,
  className = '',
  isHighlighted = false,
  testId
}: PropsWithChildren<Props>, ref: Ref<HTMLParagraphElement>) => {
  const styles = useMemo(() => {
    let output = isHighlighted ? 'font-nato-medium ' : '';
    if (variant === VARIANT.BIG) {
      output += 'text-base';
    } else if (variant === VARIANT.MEDIUM) {
      output += 'text-sm';
    } else {
      output += 'text-xs';
    }
    return output;
  }, [variant, isHighlighted]);
  return <p className={`${styles} ${className}`.trim()} ref={ref} data-testid={testId}>
        {children}
      </p>;
});
export default Paragraph;