import { useEffect } from 'react';
import Paragraph from './Paragraph';
export enum SPINNER_TYPE {
  IN_SCREEN = 'IN_SCREEN',
  ON_SCREEN = 'ON_SCREEN',
}
interface Props {
  text?: string;
  type?: SPINNER_TYPE;
}
const Spinner = ({
  text,
  type = SPINNER_TYPE.ON_SCREEN
}: Props) => {
  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);
  return <div className={`top-0 left-0 flex justify-center flex-col fixed bg-[rgba(0,0,0,0.4)] z-50 ${type === 'IN_SCREEN' ? ' w-full h-full' : ' w-screen h-screen'}`} data-testid="spinner-div" data-sentry-component="Spinner" data-sentry-source-file="Spinner.tsx">
      {text ? <Paragraph className="text-center p-4"> {text}</Paragraph> : null}
      <div className="w-12 h-12 border-t-2 border-b-2 border-green-700 rounded-full animate-spin mx-auto"></div>
    </div>;
};
export default Spinner;