import { forwardRef, ReactNode, Ref, useMemo } from 'react';
export enum VARIANT {
  LARGE,
  BIG,
  MEDIUM,
  SMALL,
}
interface Props {
  variant?: VARIANT;
  className?: string;
  children: ReactNode;
  ariaLevel?: number;
}

/**
 * The Subheading provides four variants based on the DeHaat design system
 *
 * As the sub-heading component provides a mechanism to add sub headings in
 * the code, be sure to add appropriate aria-level for better accessibility
 * of the page
 *
 * Ref: [Heading role](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/heading_role)
 *
 * @author Sumit Kumar Surana <sumit.surana@agrevolution.in>
 */
const SubHeading = forwardRef(({
  variant = VARIANT.SMALL,
  children,
  className = '',
  ariaLevel = 7
}: Props, ref: Ref<HTMLParagraphElement>) => {
  const styles = useMemo(() => {
    switch (variant) {
      case VARIANT.LARGE:
        {
          return 'text-xl font-nato-semibold';
        }
      case VARIANT.BIG:
        {
          return 'text-lg leading-6 font-nato-semibold';
        }
      case VARIANT.MEDIUM:
        {
          return 'text-lg leading-6 font-nato-medium';
        }
      default:
        {
          return 'text-base font-nato-semibold';
        }
    }
  }, [variant]);
  return <p className={`${styles} ${className}`.trim()} role="heading" aria-level={ariaLevel} ref={ref}>
        {children}
      </p>;
});
export default SubHeading;