import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import { AnchorHTMLAttributes, ReactNode } from 'react';
import { NAVIGATION_FROM_APP_CODE } from '@/constants/common';
import DeHaatCenter from '@/images/crop-problem/dehaat-center.webp';
import { AIMS_APP_CODE } from '@/models/Auth';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import { getCookieValue } from '@/utils/helper';
import DirectionsIcon from './icons/DirectionsIcon';
import PhoneIcon from './icons/PhoneIcon';
import Paragraph, { VARIANT as PARAGRAPH_VARIANT } from './Paragraph';
import SubHeading, { VARIANT as SUBHEADING_VARIANT } from './SubHeading';
export enum VARIANT {
  CART_PICKUP,
  GREEN,
}
interface Props {
  address: string;
  containerClassName?: string;
  distance?: number;
  latitude?: number;
  longitude?: number;
  name: string;
  phone_number?: string;
  variant?: VARIANT;
}
interface AnchorButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  className: string;
  icon: ReactNode;
  label: string;
  href: string;
}
const AnchorButton = ({
  className,
  icon,
  label,
  href,
  ...props
}: AnchorButtonProps) => {
  return <a href={href} className={`flex justify-center py-2.5 rounded-lg overflow-hidden ${className}`} {...props} data-sentry-component="AnchorButton" data-sentry-source-file="DCCard.tsx">
      {icon}
      <span className="font-nato-semibold text-base leading-[22px] pl-1">
        {label}
      </span>
    </a>;
};
const DCCard = ({
  address,
  containerClassName = '',
  distance,
  latitude,
  longitude,
  name,
  phone_number,
  variant = VARIANT.GREEN
}: Props) => {
  const {
    t
  } = useTranslation('nearbyDc');
  const isActionBtnHided = getCookieValue(NAVIGATION_FROM_APP_CODE) === AIMS_APP_CODE;
  return <section className={`overflow-hidden p-4 relative rounded-xl ${containerClassName} ${variant === VARIANT.GREEN ? 'bg-olivine-20 border-2 border-olivine-80' : 'bg-orange-tint-10 border border-orange-tint-20'}`} data-sentry-component="DCCard" data-sentry-source-file="DCCard.tsx">
      {variant === VARIANT.GREEN ? <>
          <div className="absolute bg-olivine-80 h-36 -left-12 opacity-20 rounded-full -top-14 w-36"></div>
          <div className="absolute bg-olivine-80 -bottom-10 h-20 opacity-20 -right-8 rounded-full w-20"></div>
        </> : null}
      <header className="flex relative z-10">
        <section className="bg-white grow-0 h-12 px-1 py-2 relative rounded-lg shrink-0 w-12">
          <Image src={DeHaatCenter} height={32} width={40} alt="Representation for DeHaat center" aria-hidden role="presentation" data-sentry-element="Image" data-sentry-source-file="DCCard.tsx" />
        </section>
        <section className="text-neutral-90 ml-4">
          <SubHeading variant={SUBHEADING_VARIANT.SMALL} data-sentry-element="SubHeading" data-sentry-source-file="DCCard.tsx">{name}</SubHeading>
          <Paragraph variant={PARAGRAPH_VARIANT.MEDIUM} className="mt-1.5" data-sentry-element="Paragraph" data-sentry-source-file="DCCard.tsx">
            {address}
          </Paragraph>
          {distance ? <Paragraph variant={PARAGRAPH_VARIANT.MEDIUM} className="mt-2.5">
              {t('distance')} {distance}
              {t('km')}
            </Paragraph> : null}
        </section>
      </header>
      {phone_number || latitude != null && longitude != null ? <footer className="grid grid-cols-2 gap-x-3 mt-4 relative z-10">
          {!isActionBtnHided && latitude != null && longitude != null ? <AnchorButton icon={<DirectionsIcon className="h-6 w-[23px] fill-primary-100" />} label={t('direction')} href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`} className="bg-white text-primary-100" target="_blank" rel="nofollow noreferrer" onClick={() => trackCustomEvent(CustomEventTypes.VENDOR_DIRECTION, address)} /> : null}
          {!isActionBtnHided && phone_number ? <AnchorButton icon={<PhoneIcon className="fill-white h-6 w-[23px]" />} label={t('call_shop')} href={`tel:${phone_number}`} className="bg-primary-100 text-white" onClick={() => trackCustomEvent(CustomEventTypes.VENDOR_CALL_SHOP, address)} /> : null}
        </footer> : null}
    </section>;
};
export default DCCard;