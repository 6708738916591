export default interface AuthToken {
  access_token: string
  expires_in: number
  id_token: string
  refresh_expires_in: number
  refresh_token: string
}

export const DFA_APP_CODE = '01'
export const AIMS_APP_CODE = '02'
export const VR_APP_CODE = '03'

export type APP_CODE_TYPE =
  | typeof DFA_APP_CODE
  | typeof AIMS_APP_CODE
  | typeof VR_APP_CODE
  | undefined

export interface CSCToken {
  access_token: string
  expires_in: number
  token_type: string
  scope: string | null
}
