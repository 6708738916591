import { forwardRef, ReactNode, Ref } from 'react';
export enum VARIANT {
  ONE,
  TWO,
}
interface Props {
  label: ReactNode;
  className?: string;
  variant?: VARIANT;
}
const ButtonLabel = forwardRef(({
  label,
  className = '',
  variant = VARIANT.ONE
}: Props, ref: Ref<HTMLSpanElement>) => {
  const styles = variant === VARIANT.ONE ? 'text-base leading-5' : 'text-sm leading-4.5';
  return <span className={`${styles} ${className}`.trim()} ref={ref}>
        {label}
      </span>;
});
export default ButtonLabel;