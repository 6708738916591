import { forwardRef, Ref, SVGAttributes } from 'react';
type Props = SVGAttributes<SVGSVGElement>;
const ProductIcon = forwardRef((props: Props, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 70 90" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="M49.28 23.8c-.3.98-.18 1.4.52 1.85 3.63 2.37 5.67 6.2 6.41 11.22.37 2.43.19 4.9.23 7.36 0 .45-.21.8-.37 1.2a58.5 58.5 0 0 1-.9 2c-.14.32-.16.61.05.9 1.13 1.53 1.38 3.36 1.22 5.36v.64H35.06v-.87a7.13 7.13 0 0 1 1.18-5.07c.24-.33.22-.66.04-1.03-.33-.68-.6-1.38-.93-2.06-.23-.5-.33-1-.3-1.57.05-2.29-.11-4.58.22-6.84.76-5.01 2.78-8.85 6.4-11.22.7-.45.83-.87.52-1.86h7.07l.02-.02Z" fill="#F2F2F2" />
    <path d="M35.06 54.33h21.38v15.5H35.06l-.02-8.63c0-2.3 0-4.58.02-6.87Z" fill="#E3E3E3" />
    <path d="M35.06 69.83h21.38c0 2.19.06 4.36 0 6.54-.04 1.36-.42 2.62-1.36 3.49-.38.33-.77.47-1.22.47h-16.2c-1.1 0-1.96-.9-2.33-2.41a7.95 7.95 0 0 1-.27-2.1c.02-1.98 0-3.98 0-5.96v-.03Z" fill="#F2F2F2" />
    <path d="M49.55 23.8h-7.6c-.6 0-.89-.47-.89-1.39v-2.97c0-.51.06-.74.43-.74h8.52c.37 0 .45.23.43.74v2.97c0 .91-.3 1.37-.89 1.38Z" fill="#E3E3E3" />
    <path d="M25.5 80.31H10.35a.26.26 0 0 1-.27-.27c.02-1-.14-2.04.1-3.03.17-.66.48-1.24.83-1.77.29-.44.57-.85.76-1.36.12-.35.19-.7.1-1.1-.3-1.81-.5-3.63-.6-5.48-.06-1.34-.1-2.7-.08-4.04.04-1.98.13-3.94.3-5.92.28-3.8.75-7.57 1.31-11.32.08-.5 0-.97-.12-1.42-.27-.97-.68-1.88-.97-2.83a9.09 9.09 0 0 1-.48-3.15v-1.55c0-.33.1-.45.4-.47h27.55c.46 0 .54.08.52.62 0 .97.06 1.93-.08 2.9a15.24 15.24 0 0 1-.95 3.22c-.19.52-.37 1.03-.5 1.59-.08.39-.1.8-.04 1.2.46 2.92.83 5.87 1.12 8.84.18 1.98.35 3.96.4 5.96.05 1.36.11 2.74.09 4.1-.06 2.56-.2 5.1-.66 7.6-.12.67 0 1.23.33 1.76.27.48.58.9.85 1.38.35.62.6 1.3.63 2.04.05.75 0 1.47 0 2.21 0 .19-.1.29-.26.31H25.48l.02-.02Z" fill="#CCC" />
    <path d="M39.74 37.2c-.04.86.04 1.81-.08 2.68h-28.3c-.09-.42-.09-.83-.09-1.28v-1.57c0-.33.09-.45.38-.45h27.57c.46 0 .54.08.54.62h-.02ZM40.9 77.77c.04.75 0 1.49 0 2.23 0 .17-.09.29-.25.29-.13.04-.25.04-.37.04H10.7c-.13 0-.25 0-.33-.04-.17 0-.3-.08-.3-.25.05-1.03-.11-2.02.13-3h30.63c.04.24.09.49.09.73h-.03ZM25.5 69.4a10.04 10.04 0 1 0 0-20.08 10.04 10.04 0 0 0 0 20.08Z" fill="#E3E3E3" />
  </svg>);
export default ProductIcon;